.autocomplete
  z-index: 999999
  height: 42px
  background: #FFFFFF
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)
  border-radius: 12px
  padding-left: 16px
  padding-right: 16px
  display: flex
  margin: 8px

.autocomplete-input
  width: 100%
  font-size: 16px
  outline-width: 0
  border: 0px
  -webkit-appearance: none

