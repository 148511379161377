@import "colors"

.MuiPaper-elevation
  border-radius: 12px !important
  align-items: center

.MuiAlert-root
  border-color: transparent !important

.MuiButton-root
  text-transform: none !important
  border-radius: 12px !important
  font-size: 16px !important
  font-weight: 500 !important
  box-shadow: none !important
  &.MuiButton-containedPrimary
    background-color: $blue-primary
  &.MuiButton-textPrimary
    color: $blue-primary

.MuiAlert-message
  display: flex
  align-items: center