.explore-dapps
  display: flex
  flex-direction: row
  padding-bottom: 18px
  flex-wrap: wrap

  .main-category
    display: flex
    background: linear-gradient(90.72deg, #B2FEAB 1.92%, #76B5FF 99.38%)
    width: 100%
    margin: 8px
    border-radius: 12px
    padding: 16px 8px
    text-decoration: none

    .title
      color: white
      font-weight: 500
      font-size: 16px
      line-height: 16px
      text-align: left
      padding-left: 6px

    .description
      padding-left: 6px
      color: white
      text-align: left
