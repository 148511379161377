@import "src/styles/vars"


.dapp
  display: flex
  flex: 1
  margin-bottom: 15px
  padding: 10px 16px 10px 8px
  background: #FFFFFF
  border-radius: 8px
  flex-direction: column

.dapp:active
  opacity: 0.5

.dapp-logo
  margin-top: 2px
  width: 48px
  min-width: 48px
  height: auto
  border-radius: 9px
  margin-right: 16px

.column
  display: flex
  flex-direction: column
  justify-content: center

.dapp-row
  flex-direction: row
  flex: 1 1

.grow
  flex: 1
  cursor: pointer

.around
  flex: 1
  justify-content: space-around

.dapp-logo-small
  min-width: 32px
  height: 32px

.dapp-name
  font-style: normal
  font-size: 16px
  font-weight: 500
  line-height: 25px
  align-items: center
  color: #001833
  text-align: left
  margin-bottom: 3px
  margin-top: 0px
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis

.dapp-name-small
  font-size: 16px
  line-height: 22px

.arrow
  cursor: pointer
  width: 15px
  transform: rotate(360deg)
  // padding: 20px 20px 20px 0

.rotate
  transform: rotate(270deg)
  // padding: 20px 0 20px 20px

.dapp-border
  border-bottom: 1px solid #9B9B9B

.dapp-desc
  margin-top: 0px
  margin-bottom: 0px
  font-style: normal
  font-weight: normal
  font-size: 12px
  line-height: 17px
  text-align: left
  color: #9B9B9B

.dapp-content
  width: 80%

.dapp-url
  font-family: Roboto, serif
  font-style: normal
  font-weight: normal
  font-size: 12px
  line-height: 17px
  text-align: left
  color: $blue-primary
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
  display: block

.dapp-close
  width: 16px
  height: 16px
  padding: 5px
  position: absolute
  right: 0px
  margin-right: 17px
  margin-top: -5px
  text-align: center
  background-color: transparent
  display: block
  box-sizing: content-box
  border: 0px

.center
  justify-content: center

.row
  display: flex
  flex-direction: row

.title
  margin-bottom: 10px
  color: $blue-primary
  font-weight: 500
  font-size: 12px

