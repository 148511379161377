@import "src/styles/vars"

.container
  height: 100%
  display: flex
  flex: 1
  justify-content: center
  background: $background-color
  position: relative
  padding: 16px
  .main
    display: flex
    text-align: center
    width: 800px
    flex-direction: column
    @include mq($until: tablet)
      width: 100%
  .btn
    position: absolute
    bottom: 16px
    width: 800px
    @include mq($until: tablet)
      width: calc(100% - 32px)
  .btn-2
    border-radius: 12px
    position: absolute
    bottom: 64px
    width: 800px
    @include mq($until: tablet)
      width: calc(100% - 32px)
.drawer-container
  background-color: $background-color
  padding: 29px 16px
  .tittle
    font-size: 16px
    font-weight: 500
  .description
    font-size: 14px
    padding-top: 16px
  .stack
    padding-top: 16px
    .paper
      display: flex
      flex-direction: row
      align-items: center
      border-radius: 12px
      padding: 12px
      margin-top: 8px
      cursor: pointer
      .avatar
        width: 44px
        height: 44px
        background-color: $grey-bg
      span
        padding-left: 12px
        font-size: 16px
        font-weight: 500
  .btn-container
    padding-top: 29px
    display: flex
    flex: 1
    justify-content: center