@import "src/styles/vars"

.category-container
  width: 800px
  flex-direction: column
  @include mq($until: tablet)
    width: 100%

.category-wrapper
  margin-left: 15px
  margin-right: 15px
  padding-bottom: 15px
