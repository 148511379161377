.header
    z-index: 0
    height: 140px
    display: flex
    position: relative
    align-items: center
    justify-content: center
    text-align: center
    .bg-img
        height: 170px
        width: 100%
        margin-top: 85px
    .logo-img
        background-color: transparent
        position: absolute
        width: 170px
        z-index: 999999999
        align-self: center
        justify-content: center
        margin-top: 10px


