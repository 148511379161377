@import "src/styles/vars"

.dapp-category-container
  display: flex
  flex: 0.5
  min-width: 50%
  text-decoration: none

.dapp-category
  position: relative
  display: flex
  flex: 1 1
  border-radius: 12px
  text-align: center
  align-items: flex-start
  margin: 8px
  padding: 8px
  flex-direction: column
  overflow: hidden
  background-color: #FFFFFF
  box-shadow: 0 0 1px rgba(40, 41, 61, 0.04), 0 2px 4px rgba(96, 97, 112, 0.16)

.dapp-category-image-container
  background: #F2F3F6
  padding: 10px
  border-radius: 50%

.dapp-category-counter
  position: absolute
  top: 10px
  right: 20px
  color: #9B9B9B
  font-size: 12px
  font-weight: bold

.dapp-category:nth-child(even)
  margin-right: 0px

.dapp-category-icon
  font-size: 32px
  line-height: 32px
  width: 32px
  height: 32px

.dapp-category-name
  font-family: 'Roboto', sans-serif
  display: flex
  font-style: normal
  font-weight: 500
  font-size: 14px
  color: #001833
  padding-top: 10px
  text-align: left
