.navbar
  display: flex
  flex: 1
  flex-direction: row
  margin-bottom: 15px
  align-items: center
  padding-top: 5px

.navbar-back
  margin: 16px 36px 16px 20px
  border: 0
  background-color: transparent
  align-self: center
  padding-top: 3px

.navbar h1
  color: #001833
  font-weight: 500
  display: flex
  flex: 1
  font-style: normal
  font-size: 20px
  line-height: 25px

